import axios from 'axios';
import querystring from 'querystring';

const environment = {
  local: {
    api: 'http://localhost:3333/',
    apiGeneral: process.env.REACT_APP_URL_BASE,
  },
  production: {
    api: process.env.REACT_APP_URL_PWA,
    apiGeneral: process.env.REACT_APP_URL_BASE,
  },
};

const baseURL =
  process?.env?.NODE_ENV === 'development'
    ? environment.local
    : environment.production;

export const api = axios.create({
  baseURL: baseURL.api,
});

export const apiGeneral = axios.create({
  baseURL: baseURL.apiGeneral,
});

export const queryBuilder = (params: Record<string, any>) => {
  const cleanParams: Record<string, any> = {};

  for (const [field, value] of Object.entries(params)) {
    if (value) {
      cleanParams[field] = value;
    }
  }

  return querystring.stringify(cleanParams);
};

export const formDataBuilder = (data: Record<string, any>) => {
  const formData = new FormData();

  for (const [field, value] of Object.entries(data)) {
    if (value instanceof File) {
      formData.append(field, value, value?.name);
    } else {
      formData.append(field, value);
    }
  }

  return formData;
};
