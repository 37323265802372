import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { format } from 'date-fns';
import {
  ShowInvoiceAction,
  IShowInvoiceState,
} from 'store/ducks/invoice/show-invoice';
import * as S from './styles';
import { moeda, peso } from 'utils';
import InvoiceItem from './InvoiceItem';
import ActorBox from './ActorBox';
import StatusBox from './StatusBox';
import Distance from './Distance';

interface IDetailsProps {
  invoiceId: number | null;
  onClose: Function;
}
const Details: React.FC<IDetailsProps> = ({ invoiceId, onClose }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { loading, data } = useSelector<RootStateOrAny, IShowInvoiceState>(
    (state) => state.showInvoice
  );

  const getInvoiceDetails = useCallback(() => {
    if (invoiceId) {
      dispatch(ShowInvoiceAction.request(invoiceId));
    }
  }, [dispatch, invoiceId]);

  const onCloseModal = useCallback(() => {
    setIsOpen(false);
    onClose();
    dispatch(ShowInvoiceAction.reset());
  }, [dispatch, onClose]);

  useEffect(() => {
    getInvoiceDetails();
  }, [getInvoiceDetails]);

  useEffect(() => {
    if (invoiceId) {
      setIsOpen(true);
    }
  }, [invoiceId]);

  useEffect(() => {
    return () => {
      dispatch(ShowInvoiceAction.reset());
    };
  }, [dispatch]);
  return (
    <S.Overlay isOpen={isOpen}>
      <S.Container>
        <S.Header>
          {loading && <S.Loading />}
          <S.Title>
            Nota fiscal,{' '}
            <span>{data && `${data.ide_numero_nf}-${data.ide_serie}`}</span>
          </S.Title>
          <S.ButtonClose onClick={onCloseModal}>
            <S.IconClose />
          </S.ButtonClose>
        </S.Header>
        {data && <StatusBox status={data?.status} />}
        {data && <Distance invoice={data} />}
        <S.DatesWrapper>
          <S.Column>
            <S.DateLabel>Data emissão</S.DateLabel>
            <S.DateValue>
              {data?.ide_data_emissao
                ? format(new Date(data.ide_data_emissao), 'dd/MM/yyyy')
                : '--/--/----'}
            </S.DateValue>
          </S.Column>
          <S.Column>
            <S.DateLabel>Data contratado</S.DateLabel>
            <S.DateValue>
              {data?.deadline_date
                ? format(new Date(data.deadline_date), 'dd/MM/yyyy')
                : '--/--/----'}
            </S.DateValue>
          </S.Column>
          <S.Column>
            <S.DateLabel>Previsão entrega</S.DateLabel>
            <S.DateValue>
              {data?.carrierPredictions[0]
                ? format(
                    new Date(
                      data.carrierPredictions[0].delivery_prediction_date
                    ),
                    'dd/MM/yyyy HH:mm'
                  )
                : '--/--/----'}
            </S.DateValue>
          </S.Column>
          <S.Column>
            <S.DateLabel>Data entrega</S.DateLabel>
            <S.DateValue>
              {data?.delivery_date
                ? format(new Date(data.delivery_date), 'dd/MM/yyyy HH:mm')
                : '--/--/----'}
            </S.DateValue>
          </S.Column>
        </S.DatesWrapper>
        <S.ActorBoxesWrapper>
          <ActorBox
            label="Remetente"
            value={data?.company.trade_name}
            location={`${data?.company.address_city}/${data?.company.address_state}`}
          />
          <ActorBox
            label="Transportadora"
            value={
              data?.carrier?.trade_name ||
              data?.carrier?.company_name ||
              data?.transp_razao_social
            }
            location={`${
              data?.carrier?.address_city || data?.transp_nome_municipio
            }/${data?.carrier?.address_state || data?.transp_uf}`}
          />
          <ActorBox
            label="Destinatário"
            value={data?.client?.trade_name || data?.client?.company_name}
            location={`${data?.client?.address_city}/${data?.client?.address_state}`}
          />
        </S.ActorBoxesWrapper>
        <S.PriceBox>
          <S.IconDollar />
          <S.PriceLabel>Valor total</S.PriceLabel>
          <S.PriceValue>
            {data?.total_valor_total_nf
              ? moeda(data?.total_valor_total_nf)
              : '-----'}
          </S.PriceValue>
          <S.WeightLabel>
            Peso bruto {'  '}
            {peso(data?.transp_peso_bruto) || '---'}
          </S.WeightLabel>
        </S.PriceBox>
        <S.InvoiceItems>
          {data?.items.map((item) => (
            <InvoiceItem item={item} key={item.id} />
          ))}
        </S.InvoiceItems>
      </S.Container>
    </S.Overlay>
  );
};

export default Details;
